<template>
    <vue-pdf-app file-name="polizaGenerada" :pdf=urlPdf></vue-pdf-app>
</template>

<script>

import pdfService from "@/services/pdfs.service.js";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import swal from 'sweetalert';
//const { VUE_APP_NOTE_URL } = process.env;
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
    name: "Home",
    props: ["userData"],
    components: {
        VuePdfApp
    },
    data: () => ({
        userData: {
            id: null,
        },
        urlPdf: '',
        resPdf: null,

    }),
    async mounted() {
        await this.$recaptchaLoaded()
        const tokenGetAmazonia = await this.$recaptcha('getAmazonia')
        try {
            var query = this.$route.query;
            var resPdfAws = await pdfService.pdfAws(query.id, query.type, query.redirect,tokenGetAmazonia);
            // console.log("asdsad: ", resPdfAws.fileFinal)
            if (resPdfAws.status == "ok") {
                this.urlPdf = resPdfAws.fileFinal;
            } else {
                throw new Error("No se encontró el registro en Aws");
            }
        } catch (error) {
            console.error(error);
            swal({ title: "No se encuentra el registro", icon: "error" });
        }
    },
};


// async mounted() {
//         //var resPdf = await pdfService.makePdf(this.$route.query.id);
//         var resPdf = await pdfService.pdfAws(this.$route.query.id);
//         if(resPdf.status == "ok"){
//             this.urlPdf = resPdf.fileFinal;
//          }else{
//             swal({title: "No se encuentra el registro", icon: "error"});
//         }
//     },
// };

</script>